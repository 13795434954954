.height-100 {
  height: 100vh;
}

.otp_card {
  width: 400px;
  border: none;
  height: 300px;
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_card h6 {
  color: #696cff;
  font-size: 20px;
}

.inputs input {
  width: 40px;
  height: 40px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-control:focus {
  box-shadow: inset 0px 0px 0px 2px #696cff !important;
}
