.image-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.image-preview .image-preview-item {
  width: 100px;
  height: 100px;
  margin: 5px;
  position: relative;
}

.image-preview .image-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-preview .image-preview-item i {
  position: absolute;
  top: 0;
  right: 0;
  color: black;
  font-size: 2rem;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
}

.loaderImage {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-uploader {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* width: 20%; */
}

.image-preview-item video {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 40px;
}
.drop-area.highlight {
  border-color: purple;
}

.drop-area:hover {
  background-color: transparent;
}
